import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import ContactInfo from 'components/ContactInfo';
import { PageProps } from 'gatsby';
import logo from "./xCLbgwb8Vy.png" // Tell webpack this JS file uses this image


const ContactPage: React.FC<PageProps> = (   pageProps ) => {
  return (
    <Layout location={pageProps.location}>
      <SEO title="Contact" />
      <ContactInfo />
      <img src={logo} style={{ width: "160px" }} />
    </Layout>
  );
};

export default ContactPage;
